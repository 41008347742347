import React from 'react'
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CC1 from '../images/CC1.jpg'
import CC2 from '../images/CC2.jpg'
import CC3 from '../images/CC3.jpg'
import CC4 from '../images/CC4.jpg'
import CCLogo from '../images/CCLogo.jpg'
import Video from '../images/ccVideo.jpg'
import Video2 from '../images/cc2Video.jpg'


export default () => (
  <div id="app" className="workpage">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Crystal Coast Page"></meta>
          <title>Crystal Coast | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/crystal-coast/" />
        </Helmet>
      <PreHeader/>
      <Header/>
        <div className="company_heading">
          <span className="company_heading--name">CRYSTAL COAST + WRALDS</span>
          <h1 className="company_heading--tagline">
          Promoting the Crystal Coast’s shoreline of opportunity.
          </h1>
        </div>
        <img className="company_gallery--image" src = {CC4} alt = "Crystal Coast, nc "/>
        <div className="company_grid">
          <div className="company_overview">
            <div className="company_overview--heading">OVERVIEW</div>
            <p className="company_overview--text">
            Carteret County includes the North Carolina beach towns of Emerald Isle
            and Atlantic Beach and the geographic region is often referred to as the
            Crystal Coast. We teamed up with the county’s economic development team
            to help them share both the goings-on and the opportunities that abound
            in this coastal community through:
            </p>
            <ul className="company_overview--list">
              <li>Native Advertising</li>
              <li>Display Ads</li>
              <li>Branded Content</li>
              <li>Custom Video</li>
              <li>Social Media</li>
            </ul>
          </div>
          <img className="company_logo" src = {CCLogo} alt ="Crystal Coast service company logo"/>

          <div className="company_gallery">
            <img className="company_gallery--image three" src = {CC1} alt ="Crystal Coast "/>
            <img className="company_gallery--image taller" src = {CC2} alt ="Crystal Coast "/>
            <img className="company_gallery--image one" src = {CC3} alt ="Crystal Coast "/>
            <a href="https://vimeo.com/379277302" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image video" src = {Video} alt ="Crystal Coast video "/>
            </a>
            <a href="https://vimeo.com/374516577" target="_blank" rel="noopener noreferrer">
              <img className="company_gallery--image video" src = {Video2} alt ="Crystal Coast video "/>
            </a>
          </div>
        </div>
    <Footer/>
  </div>
)
